<template>
  <span>
    <v-card>
      <v-card-title>
        Compensações (SERV_015)
      </v-card-title>

      <v-card-text style="max-height: 185px; overflow: auto;">
        <v-simple-table class="table-indicadores" dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th v-for="category in categories" :key="category">
                  {{ category }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td v-for="category in categories" :key="category">
                  {{
                    category in dadosCompensacoes
                      ? Number(dadosCompensacoes[category])
                      : "-" | parseNumberToFloatBrIgnoringNaN
                  }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>

      <v-card-text>
        <highcharts :options="chartsOptions" :deepCopyOnUpdate="false" />
      </v-card-text>
    </v-card>
  </span>
</template>

<script>
  import IndicadoresServicosV2Service from "@/services/IndicadoresServicosV2Service.js";

  import dayjs from "dayjs";
  import customParseFormat from "dayjs/plugin/customParseFormat";
  dayjs.extend(customParseFormat);

  import Highcharts from "highcharts";
  import HighchartsNoData from "highcharts/modules/no-data-to-display";
  HighchartsNoData(Highcharts);

  export default {
    name: "ChartsCompensacoesServicos",
    props: {
      competencias: {
        type: Array,
        required: true,
      },
      competencia_de: {
        type: String,
        required: true,
      },
      competencia_ate: {
        type: String,
        required: true,
      },
      anos: {
        type: Array,
        required: true,
      },
      meses: {
        type: Array,
        required: true,
      },
      servico: {
        type: Number,
        required: true,
      },
      visaoGraficos: {
        type: String,
        required: true,
      },
    },
    data: () => ({
      chartsOptions: {},
      dadosCompensacoes: {},
    }),
    computed: {
      categories() {
        return this.visaoGraficos === "mensal"
          ? this.setCategoriesVisaoMensal(this.competencias)
          : this.setCategoriesVisaoAnual(this.anos);
      },
    },
    mounted() {
      this.$emit("setComponentIsMounted", "chartsCompensacoesServicos");
    },
    methods: {
      setCategoriesVisaoAnual(categories) {
        // Solicitação do Tiburcio: deve haver um range de pelo menos 5 anos na visualização do dashboard
        if (categories.length < 5) {
          const stop = Number(categories[0]) + 4;
          const start = Number(categories[0]);
          const step = 1;

          categories = Array.from(
            { length: (stop - start) / step + 1 },
            (value, index) => start + index * step
          );
        }

        return categories;
      },
      setCategoriesVisaoMensal(categories) {
        // Solicitação do Tiburcio: deve haver um range de pelo menos 12 meses na visualização do dashboard
        if (categories.length < 12) {
          const startDate = dayjs(categories[0], "MM/YYYY").format("YYYY-MM");
          const endDate = dayjs(categories[0], "MM/YYYY")
            .add(11, "month")
            .format("YYYY-MM");

          let start = startDate.split("-");
          let end = endDate.split("-");
          let startYear = parseInt(start[0]);
          let endYear = parseInt(end[0]);
          let dates = [];

          for (let i = startYear; i <= endYear; i++) {
            let endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
            let startMon = i === startYear ? parseInt(start[1]) - 1 : 0;

            for (
              let j = startMon;
              j <= endMonth;
              j = j > 12 ? j % 12 || 11 : j + 1
            ) {
              let month = j + 1;
              let displayMonth = month < 10 ? "0" + month : month;
              dates.push([displayMonth, i].join("/"));
            }
          }

          categories = dates;
        }

        return categories;
      },
      getDados(
        competencia_de = this.competencia_de,
        competencia_ate = this.competencia_ate,
        servico = this.servico,
        competencias = this.competencias,
        anos = this.anos
      ) {
        this.$emit("updateLoadingDados", true);

        return IndicadoresServicosV2Service.getDadosCompensacoes(
          competencia_de,
          competencia_ate,
          servico
        )
          .then((res) => {
            this.dadosCompensacoes = res;

            const categories =
              this.visaoGraficos === "mensal"
                ? this.setCategoriesVisaoMensal(competencias)
                : this.setCategoriesVisaoAnual(anos);

            this.chartsOptions = this.setChartsOptions(categories);
          })
          .catch((err) => {
            this.$toast.error("Erro ao recuperar dados de Compensações.", "", {
              position: "topRight",
              timeout: 5000,
            });
            throw err;
          })
          .finally(() => {
            this.$emit("updateLoadingDados", false);
          });
      },
      setChartsOptions(categories) {
        return {
          chart: {
            type: "column",
            zoomType: "xy",
          },
          colors: [
            "#003a63",
            "#554584",
            "#a1478b",
            "#e04c75",
            "#ff6f4c",
            "#ffa600",
          ],
          credits: { enabled: false },
          title: { text: null },
          subtitle: { text: null },
          legend: { enabled: false },
          xAxis: {
            categories: categories,
            crosshair: true,
            title: {
              text: this.visaoGraficos === "mensal" ? "Competência" : "Ano",
            },
          },
          yAxis: {
            title: { text: null },
          },
          lang: {
            noData: "Sem dados para exibição",
          },
          series: {
            name: "Valor",
            animation: false,
            data: categories.map((category) => {
              return category in this.dadosCompensacoes
                ? {
                    x: categories.indexOf(category),
                    y: Number(this.dadosCompensacoes[category]),
                  }
                : { x: categories.indexOf(category), y: null };
            }),
            // .filter(val => val !== null && val.y !== null && val.y > 0)
          },
        };
      },
    },
    watch: {
      visaoGraficos() {
        this.chartsOptions = this.setChartsOptions(this.categories);
      },
    },
  };
</script>

<style>
  .table-indicadores table tbody tr td {
    font-size: 12px;
    padding: 0px 8px !important;
    text-align: end;
  }
  .table-indicadores table thead tr th {
    font-size: 12px;
    height: 25px;
    text-align: end !important;
    font-weight: 500;
  }
</style>
